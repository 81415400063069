import { Component, EventEmitter, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-mjesec-godina',
  templateUrl: './modal-mjesec-godina.component.html',
  styleUrl: './modal-mjesec-godina.component.scss'
})
export class ModalMjesecGodinaComponent {
  @Input() updateInputValue = new EventEmitter();
  context = localStorage.getItem('userContext');
  parsedContext = JSON.parse(this.context);
  yearsToDisplay;
  
  constructor(private bsModalRef: BsModalRef) { }

  reportForm: UntypedFormGroup;

  ngOnInit(): void { }

  onSubmit() {
    if (this.reportForm.valid) {
      this.updateInputValue.emit(this.reportForm.value);
      this.closeModal();
    } else {
      this.reportForm.markAllAsTouched();
    }
  }
  closeModal() {
    this.bsModalRef.hide();
  }
}
