import { gradoviDto } from "src/app/models/sifrarnici/gradovi/gradoviDto";
import { BaseModel } from "./base.model";
import { StatusiRadnikaDto } from "./statusi-radnika.model";
import { BankeDto } from "./banke.model";
import { KvalifikacijeDto } from "./kvalifikacije.model";
import { RadneJediniceDto } from "./radne-jedinice.model";
import { AllOpcineDto } from "./opcine.model";
import { ShemeObracunaDto } from "./sheme-obracuna.model";

export class RadniciDto extends BaseModel {
    sifra: number;
    prezime: string;
    ime: string;
    spol: number | null;
    datumRoddenja: string | null;
    imeOca: string;
    djevojaccckoPrezime: string;
    jmbg: string;
    penzioniBroj: string;
    brojRadneKnjizzice: string;
    statusRadnika: number;
    opccina: number | null;
    adresa: string;
    telefon: string;
    mobitel: string;
    eMail: string;
    kvalifikacija: number | null;
    radnaJedinica: number | null;
    radnoMjesto: number | null;
    vrstaUgovora: number | null;
    brojUgovora: string;
    datumUgovora: string | null;
    datumIstekaUgovora: string | null;
    poslovnaBanka: number | null;
    zziroRacccun: string;
    godinaStazza: number | null;
    mjeseciStazza: number | null;
    daniStazza: number | null;
    shemaObracccuna: number | null;
    faktorLicccnogOdbitka: number | null;
    formulaZaNeto: string;
    fixnaPlacca: number | null;
    brojBodova: number | null;
    bodSatnica: number | null;
    radniSatiDnevno: number | null;
    minuliRad: number | null;
    pocccetakRadnogOdnosa: string | null;
    krajRadnogOdnosa: string | null;
    prossliGodissnjiOdmor: number | null;
    godissnjiOdmorDana: number | null;
    opccinaPrebivalissta: string;
    pocccetakRadnogDana: string;
    krajRadnogDana: string;
    topliObrok: number | null;
    prevoz: number | null;
    vlasnik: boolean | null;
    iznosStimulacije: number | null;
    nocniRad: number | null;
    statusiRadnika: StatusiRadnikaDto;
    opcine: gradoviDto;
    banke: BankeDto;
    kvalifikacije: KvalifikacijeDto;
    radneJedinice: RadneJediniceDto;
    allOpcine: AllOpcineDto;
    shemeObracuna: ShemeObracunaDto;
}