import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { PagedResponse } from '../models/paged-response.model';
import { RadniciDto } from '../models/radnici.model';
import { BaseApiService } from './base-api.service';
import { AccountService } from './account.service';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { apiResponseFactory } from 'src/app/models/response/apiResponseFactory';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RadniciService {
  constructor(
    private api: BaseApiService,
    private accountService: AccountService,
  ) { }

  getAll(): Observable<RadniciDto[]> {
    return this.api.get('radnici/all').pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.map((client) => new RadniciDto(client));
        }
        return undefined;
      }),
      catchError(this.handleError<RadniciDto[]>(`getAllAS`))
    );
  }

  getPaged(params: any): Observable<PagedResponse<RadniciDto>> {
    return this.api.post('radnici/paged', params).pipe(
      map((response) => {
        const pagedModel = new PagedResponse<RadniciDto>(
          response.body.result
        );
        pagedModel.items = pagedModel.items?.map((i) => new RadniciDto(i));
        return pagedModel;
      })
    );
  }

  getById(params: any): Observable<any> {
    return this.api
      .post('radnici/by-id', params)
      .pipe(catchError(this.handleError<[]>(`radnici/by-id`)));
  }

  update(params: any = {}): Observable<any> {
    return this.api
      .post('radnici/update', params)
      .pipe(catchError(this.handleError<[]>('radnici/update')));
  }

  create(params: any = {}): Observable<any> {
    return this.api
      .post('radnici/create', params)
      .pipe(catchError(this.handleError<[]>('radnici/create')));
  }

  delete(params: any): Observable<any> {
    return this.api
      .post('radnici/delete', params)
      .pipe(catchError(this.handleError<[]>('radnici/delete')));
  }

  getReport() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': 'hr-HR',
      firmaid: this.accountService.context?.organizationId?.toString() || '',
      godina: this.accountService.context?.year?.toString() || '',
      Authorization: 'Bearer ' + this.accountService.accessToken.token,
    });
    return this.api.newRequest(
      'post',
      `${environment.apiUrl}radnici/a4-pdf`,
      {
        broj: 1,
      },
      {},
      {
        headers,
        responseType: 'blob',
        observe: 'response'
      }
    );
  }

  getPlatneListe(godina, mjesec) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': 'hr-HR',
      firmaid: this.accountService.context?.organizationId?.toString() || '',
      godina: this.accountService.context?.year?.toString() || '',
      Authorization: 'Bearer ' + this.accountService.accessToken.token,
    });
    return this.api.newRequest(
      'post',
      `${environment.apiUrl}radnici/platne-liste-radnika`,
      {
        godina: godina,
        mjesec: mjesec
      },
      {},
      {
        headers,
        responseType: 'blob',
        observe: 'response'
      }
    );
  }

  preracunatiNetoZaIsplatu(params: any): Observable<any> {
    return this.api
      .post('radnici/preracunati-neto-za-isplatu', params)
      .pipe(catchError(this.handleError<[]>('radnici/preracunati-neto-za-isplatu')));
  }

  preracunatiMinuliRad(params: any): Observable<any> {
    return this.api
      .post('radnici/preracunati-minuli-rad', params)
      .pipe(catchError(this.handleError<[]>('radnici/preracunati-minuli-rad')));
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;

      return throwError(error);
    };
  }

}
