<div class="card card-body mb-0">
    <form [formGroup]="reportForm" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label class="font-weight-bold"> {{"PROPERTIES.Mjesec" | translate}} </label>
                    <div class="form-group">
                        <div>
                            <input formControlName="mjesec" class="form-control digits" type="number" min="1"
                                max="12" />
                        </div>
                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                  validation: 'required',
                  message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
                  control: this.reportForm.controls.mjesec
                }">
                        </ng-container>

                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                            validation: 'isBeetweenLength',
                            message: 'VALIDATORS.Mjesec',
                            control: this.reportForm.controls.mjesec
                          }">
                        </ng-container>
                    </div>
                </div>

                <div class="form-group">
                    <label class="font-weight-bold"> {{"PROPERTIES.Godina" | translate}} </label>
                    <div class="form-group">
                        <div>
                            <ng-select [clearable]="false" placeholder="Godina" formControlName="godina">
                                <ng-option *ngFor="let year of yearsToDisplay" [value]="year">
                                    {{ year }}
                                </ng-option>
                            </ng-select>
                        </div>
                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                validation: 'required',
                                message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
                                control: this.reportForm.controls.godina
                                }">
                        </ng-container>


                    </div>
                </div>
            </div>

            <div class="col-12 text-right mt-2 mb-0">
                <button class="btn btn-light mr-2" (click)="closeModal()" type="button">
                    {{"PROPERTIES.Otkazi" | translate}}
                </button>
                <button class="btn btn-primary" type="submit">
                    {{"PROPERTIES.SpremiIzmjene" | translate}}
                </button>
            </div>
        </div>
    </form>
</div>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
    <ng-container *ngIf="control.hasError(validation) && control.touched">
        <small class="text-danger">{{ message | translate }}</small>
    </ng-container>
</ng-template>