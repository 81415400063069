import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { Observable, throwError } from 'rxjs';
import { getAcceptLanguage } from '../helpers/getAcceptLanguage';
import { HttpHeaders } from '@angular/common/http';
import { AccountService } from './account.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PdvReportingService {

  constructor(private api: BaseApiService,
    private accountService: AccountService,) { }

  getKifKompletanPdf(params: any) {
    var lang = getAcceptLanguage.getLocalStorageLanguage();
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': lang,
      firmaid: this.accountService.context?.organizationId?.toString() || '',
      godina: this.accountService.context?.year?.toString() || '',
      Authorization: 'Bearer ' + this.accountService.accessToken.token,
    });
    return this.api.newRequest(
      'post',
      `${environment.apiUrl}pdv/reporting/kif-kompletan-datum`,
      {
        od: params.od,
        do: params.do,
      },
      {},
      {
        headers,
        responseType: 'blob',
      }
    );
  }

  getKufKompletanPdf(params: any) {
    var lang = getAcceptLanguage.getLocalStorageLanguage();
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': lang,
      firmaid: this.accountService.context?.organizationId?.toString() || '',
      godina: this.accountService.context?.year?.toString() || '',
      Authorization: 'Bearer ' + this.accountService.accessToken.token,
    });
    return this.api.newRequest(
      'post',
      `${environment.apiUrl}pdv/reporting/kuf-kompletan-datum`,
      {
        od: params.od,
        do: params.do,
      },
      {},
      {
        headers,
        responseType: 'blob',
      }
    );
  }

  getPdvPrijavaPdf(params: any) {
    var lang = getAcceptLanguage.getLocalStorageLanguage();
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': lang,
      firmaid: this.accountService.context?.organizationId?.toString() || '',
      godina: this.accountService.context?.year?.toString() || '',
      Authorization: 'Bearer ' + this.accountService.accessToken.token,
    });
    return this.api.newRequest(
      'post',
      `${environment.apiUrl}pdv/reporting/pdv-prijava`,
      {
        mjesec: params.mjesec,
      },
      {},
      {
        headers,
        responseType: 'blob',
      }
    );
  }







  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;

      return throwError(error);
    };
  }
}
